<template>
  <div class="container">
    <el-row class="my-point">
      <span>我的点值：</span><span>{{ point }}</span>
    </el-row>
    <ul class="info-detail">
      <li>
        <span>家庭编号</span><span>{{ user.info.family_code }}</span>
      </li>
      <li>
        <span>成员姓名</span><span>{{ user.info.name }}</span>
      </li>
      <li>
        <span>城市</span><span>{{ user.info.city }}</span>
      </li>
      <li>
        <span>访问员姓名</span><span>{{ user.info.accesser_name }}</span>
      </li>
      <li>
        <span>合作项目</span><span>{{ nameMap[user.info.project_name] }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { service } from '@/utils/axios'
export default {
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      point: null,
      nameMap: {
        美妆项目: '个人美妆消费日记',
        婴儿项目: '婴儿消费日记',
        户外项目: '户外消费日记',
        家庭消费项目: '家庭消费日记',
        个人护理项目: '个人护理习惯日记',
      },
    }
  },
  methods: {
    async init() {
      const resPoint = await service.post('app/get_point_log')
      this.point = resPoint.data.data.totle_point
    },
  },
  created() {
    this.init()
  },
}
</script>
<style lang="less" scoped>
.container {
  padding: 20px;
  margin: 0;
  background-color: #fff;
  box-sizing: border-box;

  .my-point {
    border: 1px solid #dddddd;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    span:first-child {
      font-size: 14px;
      color: #333333;
    }
    span:last-child {
      font-size: 18px;
      color: #ec7717;
    }
  }
  .info-detail {
    li {
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      padding: 0 20px;
    }
  }
}
</style>
